:root body:not(.dark) {
  --primary:#006876;
  --on-primary:#ffffff;
  --primary-container:#a1efff;
  --on-primary-container:#001f25;
  --secondary:#4a6268;
  --on-secondary:#ffffff;
  --secondary-container:#cde7ed;
  --on-secondary-container:#051f23;
  --tertiary:#545d7e;
  --on-tertiary:#ffffff;
  --tertiary-container:#dbe1ff;
  --on-tertiary-container:#101a37;
  --error:#ba1a1a;
  --on-error:#ffffff;
  --error-container:#ffdad6;
  --on-error-container:#410002;
  --background:#fbfcfd;
  --on-background:#191c1d;
  --surface:#f8fafa;
  --on-surface:#191c1d;
  --surface-variant:#dbe4e6;
  --on-surface-variant:#3f484a;
  --outline:#6f797b;
  --outline-variant:#bfc8ca;
  --shadow:#000000;
  --scrim:#000000;
  --inverse-surface:#2e3132;
  --inverse-on-surface:#eff1f2;
  --inverse-primary:#44d8f1;
  --surface-dim:#d8dadb;
  --surface-bright:#f8fafa;
  --surface-container-lowest:#ffffff;
  --surface-container-low:#f2f4f4;
  --surface-container:#eceeef;
  --surface-container-high:#e6e8e9;
  --surface-container-highest:#e1e3e3;
}

:root body.dark {
--primary:#78dc77;
--on-primary:#00390a;
--primary-container:#005313;
--on-primary-container:#94f990;
--secondary:#baccb3;
--on-secondary:#253423;
--secondary-container:#3b4b38;
--on-secondary-container:#d5e8cf;
--tertiary:#a0cfd4;
--on-tertiary:#00363b;
--tertiary-container:#1f4d52;
--on-tertiary-container:#bcebf0;
--error:#ffb4ab;
--on-error:#690005;
--error-container:#93000a;
--on-error-container:#ffb4ab;
--background:#1a1c19;
--on-background:#e2e3dd;
--surface:#121411;
--on-surface:#e2e3dd;
--surface-variant:#424940;
--on-surface-variant:#c2c9bd;
--outline:#8c9388;
--outline-variant:#424940;
--shadow:#000000;
--scrim:#000000;
--inverse-surface:#e2e3dd;
--inverse-on-surface:#2f312d;
--inverse-primary:#006e1c;
--surface-dim:#121411;
--surface-bright:#383a36;
--surface-container-lowest:#0c0f0c;
--surface-container-low:#1a1c19;
--surface-container:#1e201d;
--surface-container-high:#282b27;
--surface-container-highest:#333531;
}
